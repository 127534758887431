import { lazy } from "react";
import { usertypes } from "../../constants";

export default [
  {
    title: "Questionnaire",
    component: lazy(() => import("./Questionnaire")),
    url: "/questionnaire",
    requirePermission: true,
    userTypes: [usertypes.patient, usertypes.parent, usertypes.clinician],
  },
  {
    title: "Questionnaire",
    component: lazy(() => import("./Questionnaire")),
    url: "/questionnaire/overview",
    requirePermission: true,
    userTypes: [usertypes.patient, usertypes.parent, usertypes.clinician],
  },
  {
    title: "Questionnaire Dashboard",
    component: lazy(() => import("./QuestionnaireDashboard")),
    url: "/questionnaire/dashboard",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin],
  },
  {
    title: "Questionnaire Response",
    component: lazy(() => import("./QuestionnaireResponse")),
    url: "/questionnaire/response",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin],
  },
  {
    title: "Questionnaire Scheduled",
    component: lazy(() => import("./QuestionnaireScheduled")),
    url: "/questionnaire/scheduled",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin],
  },
  {
    title: "Questionnaire Library",
    component: lazy(() => import("./QuestionnaireLibrary")),
    url: "/questionnaire/Library",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin],
  },
  {
    title: "Questionnaire New",
    component: lazy(() => import("./QuestionnairePatientNew")),
    url: "/questionnaire/new-question",
    requirePermission: true,
    userTypes: [usertypes.patient, usertypes.parent, usertypes.clinician],
  },
  {
    title: "Questionnaire Draft",
    component: lazy(() => import("./QuestionnairePatientDraft")),
    url: "/questionnaire/draft-question",
    requirePermission: true,
    userTypes: [usertypes.patient, usertypes.parent, usertypes.clinician],
  },
  {
    title: "Questionnaire Completed",
    component: lazy(() => import("./QuestionnairePatientCompleted")),
    url: "/questionnaire/completed-question",
    requirePermission: true,
    userTypes: [usertypes.patient, usertypes.parent, usertypes.clinician],
  },
];
