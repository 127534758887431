import authModules from './pages/auth';
import dashboardModules from './pages/dashboard';
import commonModules from './pages/common';
import Resources from './pages/resources';
import Questionnaire from './pages/questionnaires';
import Forum from './pages/forum';
import UserManagementModules from './pages/UserManagement';

export default [
  ...authModules,
  ...dashboardModules,
  ...commonModules,
  ...Resources,
  ...Questionnaire,
  ...Forum,
  ...UserManagementModules,
];
