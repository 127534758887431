import React, {
  // useState,
  useMemo,
  // useEffect
} from "react";
import DataTable from "react-data-table-component";
import "../style/components/table.css";
import "../style/components/userTable.css";
import Loading from "./Loader";
import { SearchInut } from "./forms/SearchInut";
import { SelectDropdown } from "./SelectDropdown";
import Button from "./Button";
// import { CustomPagination } from './CustomPagination';

const Style = () => {
  const tabIndex = 0;
  return (
    <div tabIndex={tabIndex} className="no-data">
      There are no records to display.
    </div>
  );
};

// function getNumberOfPages(rowCount: any, rowsPerPage: any) {
//   return Math.ceil(rowCount / rowsPerPage);
// }

const Table = ({
  columns,
  data,
  customSort,
  paginationTotalRows,
  onChangePage,
  onChangeRowsPerPage,
  progressPending,
  showfilter,
  onChangeFilter,
  filterOptions = [],
  filterValue = "",
  onChangeSearch,
  searchValue = "",
  searchId = "search",
  filterById = "filterBy",
  selectableRows,
  btnText1,
  btnclass1,
  btnClick1,
  btnText2,
  btnclass2,
  btnClick2,
  btnClick2Disabled,
  disabledShare,
  handleSelectedRowsChange,
  clearSelectedRows,
  loading,
  ...props
}: any) => {
  // const [loading, setLoading] = useState(false);
  // const [filterBy, setFilterBy] = useState('');
  // useEffect(() => {
  //   setLoading(false);
  // }, [data]);

  // const pageCount = getNumberOfPages(paginationTotalRows, 5);

  // search based on API
  // function handleChange(value: any) {
  //   setLoading(true);
  // }

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="data_table_header">
        <div
          className={`datatabel-search-block ${showfilter && "items-baseline"}`}
        >
          <div className="gap-5 w-full">
            <SearchInut
              addClass="w-full"
              type="text"
              label="Search"
              id={searchId}
              iconShow
              name="role"
              value={searchValue}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              onKeyUp={(e: any) => {
                if (e.key === " " && searchValue.trim() === "")
                  onChangeSearch("");
              }}
              onKeyDown={(e: any) => {
                if (e.key === " " && searchValue.trim() === "")
                  onChangeSearch("");
              }}
            />

            <div className="search-loading">
              <div>{loading && <Loading />}</div>
              {searchValue !== "" && (
                <div className="link-menu">
                  <span
                    className="mt-2 link-menu"
                    onClick={() => {
                      onChangeSearch("");
                    }}
                  >
                    Clear
                  </span>
                </div>
              )}
            </div>
          </div>
          {showfilter && (
            <div className="sm:w-[250px]">
              <div className="sort-and-filter onkeyup">
                <SelectDropdown
                  type="text"
                  name="filterBy"
                  id={filterById}
                  label="Filter by"
                  isSearchable={true}
                  onChange={(e: any) => {
                    onChangeFilter(e.value);
                  }}
                  value={filterValue}
                  options={filterOptions}
                />
              </div>
            </div>
          )}
        </div>
        <div className="table_btn_block">
          {btnText1 && (
            <Button
              type="button"
              text={btnText1}
              addClass={`secondary-btn ${btnclass1}`}
              onClick={btnClick1}
              disabled={disabledShare as boolean}
            />
          )}
          {btnText2 && (
            <Button
              type="button"
              text={btnText2}
              addClass={`secondary-btn ${btnclass2}`}
              onClick={btnClick2}
              disabled={btnClick2Disabled as boolean}
            />
          )}
        </div>
      </div>
    );
  }, [
    searchValue,
    filterValue,
    filterOptions,
    disabledShare,
    clearSelectedRows,
    loading,
  ]);

  return (
    <>
      <div className="table-blk">
        <DataTable
          columns={columns}
          data={data}
          paginationPerPage={props.paginationPerPage}
          paginationpage
          pagination
          paginationServer
          selectableRows={selectableRows}
          onSelectedRowsChange={handleSelectedRowsChange}
          clearSelectedRows={clearSelectedRows}
          onChangePage={onChangePage}
          paginationTotalRows={props.paginationTotalRows || paginationTotalRows}
          onChangeRowsPerPage={onChangeRowsPerPage}
          progressPending={progressPending}
          // onSort={handleSort}
          sortFunction={customSort}
          progressComponent={<Loading addClass="w-12 h-12" />}
          // responsive
          noDataComponent={loading ? <Loading /> : <Style />}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          {...props}
        />
      </div>
      {/* <CustomPagination pageCount={pageCount} handlePageClick currentPage={1} /> */}
    </>
  );
};

export default Table;
