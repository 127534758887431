import React, { useRef, useEffect, useState } from 'react';

import { ReactComponent as PopupClose } from '../../assets/images/close.svg';
import { useDialogPolyfill } from './useDialogPolyfill';
import './Dialog.css';

interface DialogPopupProps {
  closeOnOutsideClick?: any;
  onRequestClose: any;
  open: any;
  children?: any;
  userdata?: any;
  PopupSize?: 'sm' | 'md' | 'lg';
  heading?: string;
  content?: string;
  text?: string;
  showIcon?: boolean;
  showHeading?: boolean;
  headingBg?: string;
}

export const DialogPopup: React.FC<DialogPopupProps> = ({
  closeOnOutsideClick,
  onRequestClose,
  open,
  children,
  PopupSize,
  showIcon = true,
  showHeading = false,
  heading,
  content,
  text,
  headingBg,
}) => {
  const dialogRef = useRef<any>(null);
  const lastActiveElement = useRef<any>(null);
  const firstRender = useRef<boolean>(true);
  const [tabActive, settabActive] = useState<boolean>(true);

  useDialogPolyfill(dialogRef);

  // Trap Inner Popup focus using Tab
  function trapTabKey(
    e: any,
    firstTabStop: any,
    lastTabStop: any,
    dialogNode: any
  ) {
    // Check for TAB key press
    if (e.keyCode === 9) {
      // SHIFT + TAB
      if (e.shiftKey) {
        if (document.activeElement === firstTabStop) {
          e.preventDefault();
          lastTabStop.focus();
        }

        // TAB
      } else if (document.activeElement === lastTabStop) {
        e.preventDefault();
        firstTabStop.focus();
      }
    }

    // ESCAPE
    if (e.keyCode === 27) {
      dialogNode.close();
      settabActive(true);
    }
  }

  useEffect(() => {
    // prevents calling imperative methods on mount since the polyfill will throw an error since we are not using the `open` attribute
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const dialogNode = dialogRef.current;
      if (open) {
        lastActiveElement.current = document.activeElement;
        dialogNode.showModal();

        if (typeof window != 'undefined' && window.document) {
          document.body.style.overflow = 'hidden';
        }

        // Find all focusable children
        const focusableElementsString =
          'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

        let focusableElements = dialogNode.querySelectorAll(
          focusableElementsString
        );
        focusableElements = Array.prototype.slice.call(focusableElements);
        const firstTabStop = focusableElements[0];
        const lastTabStop = focusableElements[focusableElements.length - 1];

        // Focus first child
        // firstTabStop.focus();

        // Traps focus popup
        dialogNode.addEventListener('keydown', (event: any) => {
          trapTabKey(event, firstTabStop, lastTabStop, dialogNode);
          if (event.keyCode === 9) {
            settabActive(false);
          }
        });
      } else {
        dialogNode.close();
        document.body.style.overflow = 'unset';
        settabActive(true);
        if (
          lastActiveElement &&
          lastActiveElement.current &&
          lastActiveElement.current !== null
        ) {
          lastActiveElement.current.focus();
        }
      }
    }
  }, [open]);

  useEffect(() => {
    const dialogNode = dialogRef.current;
    const handleCancel = (event: any) => {
      event.preventDefault();
      onRequestClose();
    };
    if (dialogNode) {
      dialogNode.addEventListener('cancel', handleCancel);
    }
    return () => {
      if (dialogNode) {
        dialogNode.removeEventListener('cancel', handleCancel);
      }
    };
  }, [onRequestClose]);

  function handleOutsideClick(event: any) {
    const dialogNode = dialogRef.current;
    if (closeOnOutsideClick && event.target === dialogNode) {
      onRequestClose();
    }
  }

  return (
    <dialog ref={dialogRef} onClick={handleOutsideClick}>
      <div className='popup-wrapper'>
        <div className={`popup-card ${PopupSize}`}>
          {showIcon && (
            <div className='popup-card-close'>
              <button
                className={`cursor-pointer ${
                  open && tabActive ? 'tab_inactive' : ''
                }`}
                type='button'
                onClick={onRequestClose}
              >
                <PopupClose />
                &#8203;
              </button>
            </div>
          )}
          {showHeading && (
            <div className={`Ques_pop_head ${headingBg ? headingBg : ''}`}>
              {heading && <h3 dangerouslySetInnerHTML={{ __html: heading }} />}
              {content && <p>{content}</p>}
              {text && <p className='mt-8'>{text}</p>}
            </div>
          )}
          <div className='popup-card-content'>
            {open && (
              <div className={`popup-body ${showHeading ? 'pd' : ''}`}>
                {children}
              </div>
            )}
          </div>
        </div>
        <div className='backdrop' />
      </div>
    </dialog>
  );
};
