import axiosAPI from "../middlewares/axios-interceptor";
import * as RequestType from "../types/services";

const signin = (data: RequestType.Signin) => axiosAPI.post("/signin", data);

const forgotPassword = (data: any) => axiosAPI.post("/forgot-password", data);

const createOrChangePassword = (data: RequestType.CreateOrChangePassword) =>
  axiosAPI.post("/set-password", data);

const setPassword = (data: RequestType.SetPassword) =>
  axiosAPI.post("/set-password", data);

const logOut = (data: any) => axiosAPI.put("/logout", data);

const changeCurrentPassword = (data: RequestType.changePassword) =>
  axiosAPI.put("/users/change-password", data);

const getUserById = (id: string) => axiosAPI.get(`/profile-details/${id}`);

const updateProfile = (data: RequestType.UpdateProfile) =>
  axiosAPI.put("/users/update-profile", data);

// const manageUser = (id: string, data: RequestType.ManageUser) => axiosAPI.put(`/users/manage-users/${id}`, data);

const getAllUsers = (
  page: number,
  perPage: number,
  userType: string,
  searchText: string,
  sortBy: string,
  sorting: string
) =>
  axiosAPI.get(
    `/get-all-users?page=${page}&limit=${perPage}&searchText=${searchText}&userType=${userType}&sortBy=${sortBy}&sorting=${sorting}`
  );

const getAllRoles = () => axiosAPI.get(`/role/dropdown`);

const generateInviteLink = (data: RequestType.generateInviteLink) =>
  axiosAPI.post(`/generate-invite-link`, data);

const inviteNewUser = (data: RequestType.InviteNewUser) =>
  axiosAPI.post("/create-user-account", data);

const updateUserDetails = (data: RequestType.UpdateUserDetails) =>
  axiosAPI.put("/admin/update-user-details", data);

const getConsentForm = () => axiosAPI.get(`/questionnaire/consent/list`);
const getConsentFormByUser = (id: string) =>
  axiosAPI.get(`/questionnaire/consent/answers/user?userId=${id}`);

export {
  signin,
  forgotPassword,
  createOrChangePassword,
  setPassword,
  getUserById,
  updateProfile,
  updateUserDetails,
  changeCurrentPassword,
  logOut,
  // manageUser,
  getAllUsers,
  inviteNewUser,
  generateInviteLink,
  getAllRoles,
  //Forums
  getConsentForm,
  getConsentFormByUser,
};
