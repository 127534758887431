import React from "react";
import { DialogPopup } from "./dialog/DialogPopup";
import { Button } from ".";

interface ErrorPopupProps {
  open: boolean;
  handleClose: () => void;
  message?: string;
}

export const ErrorPopup: React.FC<ErrorPopupProps> = (props) => {
  const { open, handleClose, message } = props;

  return (
    <DialogPopup
      showHeading
      heading={`Error`}
      // headingBg="!bg-background-danger"
      PopupSize="md"
      open={open}
      onRequestClose={handleClose}
      closeOnOutsideClick={false}
    >
      <div className="main_card">
        <div className="fl-block">
          {/* <div
            className="mb-6 text-center"
            dangerouslySetInnerHTML={{ __html: message }}
          /> */}
          <div className="text-center">
            <span className="text-md block text-font-dark">
              {message ? message : "Something went wrong!"}
            </span>
            <p> {message ? "" : "please contact admin."} </p>
          </div>
          <div className="footer-btn text-center">
            <Button
              text={"CLOSE"}
              addClass="secondary-btn dark !px-12"
              // addClass="!px-12"
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </DialogPopup>
  );
};

export default ErrorPopup;
