const usertypes = {
    clinician: 'clinician',
    patient: 'patient',
    parent: 'parent',
    admin: 'admin',
    superAdmin: 'superadmin'
};

const userRoles: any = {
    superadmin: 'Super Admin',
    admin: 'Admin',
    clinician: 'Clinician',
    patient: 'Young Patient',
    parent: 'Carer / Parent',
};

const userStatus = {
    active: 'Active',
    delete: 'deleted',
    requested: 'Request',
    rejected: 'Rejected'
};

const trustStatus = {
    active: 'Active',
    request: 'Requested',
    rejected: 'Rejected',
    archived: 'Archived'
};



export {
    usertypes,
    userStatus,
    trustStatus,
    userRoles
};
