import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../../utils";
import { LocalStorageUserDetails } from "../../types";

import { Link, useLocation } from "react-router-dom";
import { usertypes } from "../../constants";

import "../styles/Nav.css";
// import { SubNav } from './SubNav';

const userDetails: LocalStorageUserDetails = getLocalStorage("userDetails");

export const Navbare: React.FC<any> = () => {
  const primaryMenu: any = [];
  const secondaryMenu: any = [];
  const thirdMenu: any = [];
  const location = useLocation();
  // const [resourceTypes, setResourceTypes] = useState([]);

  const tabIndex = 0;

  if (
    userDetails &&
    (userDetails.usertype == usertypes.patient ||
      userDetails.usertype == usertypes.clinician ||
      userDetails.usertype == usertypes.parent)
  ) {
    primaryMenu.push(
      {
        title: "RESOURCES",
        link:
          userDetails.usertype == usertypes.clinician
            ? "/resources"
            : "/resources/recommended-resources",
        isActive: false,
      },
      {
        title: "QUESTIONNAIRES",
        link: "/questionnaire",
        isActive: false,
      },
      {
        title: "FORUM",
        link: "/forum",
        isActive: false,
      }
    );
  } else if (userDetails && userDetails.usertype == "admin") {
    primaryMenu.push(
      {
        title: "RESOURCES",
        link: "/resources",
        isActive: false,
      },
      {
        title: "QUESTIONNAIRES",
        link: "/questionnaire/dashboard",
        isActive: false,
      },
      {
        title: "FORUM",
        link: "/forum/overview",
        isActive: false,
      },
      {
        title: "USER MANAGEMENT",
        link: "/user-management",
        isActive: false,
      }
    );
  } else if (userDetails && userDetails.usertype == "superadmin") {
    primaryMenu.push(
      {
        title: "RESOURCES",
        link: "/resources",
        isActive: false,
      },
      {
        title: "QUESTIONNAIRES",
        link: "/questionnaire/dashboard",
        isActive: false,
      },
      {
        title: "FORUM",
        link: "/forum/overview",
        isActive: false,
      },
      {
        title: "USER MANAGEMENT",
        link: "/user-management",
        isActive: false,
      }
    );
  }

  if (location && location.pathname.split("/")[1] == "resources") {
    if (
      userDetails &&
      (userDetails.usertype == usertypes.patient ||
        userDetails.usertype == usertypes.parent)
    ) {
      secondaryMenu.push(
        {
          title: "RECOMMENDED RESOURCES",
          link: "/resources/recommended-resources",
          isActive: false,
        },
        {
          title: "SAVED RESOURCES",
          link: "/resources/saved-resources",
          isActive: false,
        }
      );
    } else {
      secondaryMenu.push(
        {
          title: "TYPES OF RESOURCES",
          link: "/resources/type-resources",
          isActive: false,
        },
        {
          title: "SHARED RESOURCES",
          link: "/resources/shared-resources",
          isActive: false,
        }
      );
    }
  }

  if (location && location.pathname.split("/")[1] == "questionnaire") {
    if (
      userDetails &&
      (userDetails.usertype == usertypes.patient ||
        userDetails.usertype == usertypes.clinician ||
        userDetails.usertype == usertypes.parent)
    ) {
      secondaryMenu.push(
        {
          title: "OVERVIEW",
          link: "/questionnaire/overview",
          isActive: false,
        },
        {
          title: "DRAFT QUESTIONNAIRES",
          link: "/questionnaire/draft-question",
          isActive: false,
        },
        {
          title: "NEW QUESTIONNAIRES",
          link: "/questionnaire/new-question",
          isActive: false,
        },
        {
          title: "COMPLETED QUESTIONNAIRES",
          link: "/questionnaire/completed-question",
          isActive: false,
        }
      );
    } else {
      secondaryMenu.push(
        {
          title: "OVERVIEW",
          link: "/questionnaire/dashboard",
          isActive: false,
        },
        {
          title: "QUESTIONNAIRE LIBRARY",
          link: "/questionnaire/Library",
          isActive: false,
        },
        {
          title: "SHARED QUESTIONNAIRES",
          link: "/questionnaire/response",
          isActive: false,
        },
        {
          title: "SCHEDULED QUESTIONNAIRES",
          link: "/questionnaire/scheduled",
          isActive: false,
        }
      );
    }
  } else if (location && location.pathname.split("/")[1] == "forum") {
    if (
      userDetails &&
      (userDetails.usertype == usertypes.patient ||
        userDetails.usertype == usertypes.clinician ||
        userDetails.usertype == usertypes.parent)
    ) {
      // no menu
    } else {
      secondaryMenu.push(
        {
          title: "OVERVIEW",
          link: "/forum/overview",
          isActive: false,
        },
        {
          title: "UPCOMING FORUMS",
          link: "/forum/upcoming",
          isActive: false,
        },
        {
          title: "COMPLETED FORUMS",
          link: "/forum/completed",
          isActive: false,
        }
      );
    }
  }
  // setResourceTypes(finalTypes);

  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      const windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 110 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  return (
    <>
      <div className={`${stickyClass}`}>
        <div className="header_nav_fix">
          <nav className="primary-nav">
            <ul className="nav-list">
              {primaryMenu.map((item: any) => (
                <React.Fragment key={item.title}>
                  <li className={`nav-item `} tabIndex={tabIndex}>
                    <Link
                      className={`no-underline ${
                        location.pathname.split("/")[1] ===
                        item.link.split("/")[1]
                          ? "active"
                          : ""
                      }`}
                      to={item.link}
                    >
                      {item.title}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </nav>
        </div>
        {secondaryMenu && secondaryMenu.length > 0 && (
          <nav className="secondary-nav">
            <div className="nav-list">
              {secondaryMenu.map((item: any) => (
                <React.Fragment key={item.title}>
                  <Link
                    className={`nav-item no-underline ${
                      location.pathname.includes(item.link) ? "active" : ""
                    }`}
                    to={item.link}
                  >
                    {item.title}
                  </Link>
                </React.Fragment>
              ))}
            </div>
          </nav>
        )}
        {thirdMenu && thirdMenu.length > 0 && (
          <nav className="third-nav">
            <div className="nav-list">
              {thirdMenu.map((item: any) => (
                <React.Fragment key={item.title}>
                  <Link
                    className={`nav-item no-underline ${
                      location.pathname.includes(item.link) ? "active" : ""
                    }`}
                    to={item.link}
                  >
                    {item.title}
                  </Link>
                </React.Fragment>
              ))}
            </div>
          </nav>
        )}
        {/* <SubNav
        userdata={resourceTypes}
        onClick={(e: string) => {
          setClearSelectedRows(true);
          setSelectedResource([]);
          setType(e);
        }}
      /> */}
      </div>
    </>
  );
};
