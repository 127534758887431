import { lazy } from 'react';
import { usertypes } from '../../constants';

export default [
  {
    title: 'User Management',
    component: lazy(() => import('./UserManagement')),
    url: '/user-management',
    requirePermission: true,
    userTypes: [usertypes.admin, usertypes.superAdmin],

  },
];
