import { lazy } from "react";
import { usertypes } from "../../constants";

export default [
  {
    title: "Forum Page",
    component: lazy(() => import("./Forum")),
    url: "/forum",
    requirePermission: true,
  },

  {
    title: "Forum Overview",
    component: lazy(() => import("./ForumOverview")),
    url: "/forum/overview",
    requirePermission: true,
  },
  {
    title: "Forum Chatbot",
    component: lazy(() => import("./ForumChat")),
    url: "/forum/chatbot/:id",
    requirePermission: true,
  },
  {
    title: "Forum Live Session",
    component: lazy(() => import("./ForumLiveSession")),
    url: "/forum/live-session/:id",
    requirePermission: true,
  },
  {
    title: "Forum Upcoming",
    component: lazy(() => import("./ForumUpcoming")),
    url: "/forum/upcoming",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin],
  },
  {
    title: "Forum Completed",
    component: lazy(() => import("./ForumCompleted")),
    url: "/forum/completed",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin],
  },
];
