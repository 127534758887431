import axiosAPI from "../middlewares/axios-interceptor";
import * as RequestType from "../types/services";

const getAllForums = () => axiosAPI.get(`/get-my-forum`);

const joinForum = (id: string) => axiosAPI.put(`/join-forum/${id}`);

const createForum = (data: RequestType.CreateForum) =>
  axiosAPI.post(`/create-forum`, data);

const getForumChatbox = (fId: string) =>
  axiosAPI.get(`/get-all-forum-post/${fId}`);

const putForumChatboxReview = (data: RequestType.ForumChatboxReview) =>
  axiosAPI.put(`/review-post`, data);

const createForumChatMessage = (data: any) =>
  axiosAPI.post(`/create-post`, data);

const closeDeclinedMessage = (fdId: string) =>
  axiosAPI.put(`/close-notification/${fdId}`);

const getAllForumsByAdmin = (search: string) =>
  axiosAPI.get(`/get-all-forum?searchText=${search}`);

const getAllForumsByGroups = (
  page: number,
  perPage: number,
  filterBy: string,
  search: string
) =>
  axiosAPI.get(
    `/get-all-forum-filter?page=${page}&limit=${perPage}&filterBy=${filterBy}&searchText=${search}`
  );

const updateForum = (data: RequestType.UpdateForum) =>
  axiosAPI.put(`/update-forum`, data);

const getForumsByEdit = (id: string) =>
  axiosAPI.get(`/get-forum-details/${id}`);

const sendReminderForum = (data: RequestType.SendReminderForum) =>
  axiosAPI.post(`/instant-reminder`, data);

const deleteUpcommingForum = (id: string) =>
  axiosAPI.put(`/delete-forum/${id}`);

const getForumParticipantList = (id: string) =>
  axiosAPI.get(`/get-participant-list/${id}`);

const deleteFormChatFdId = (id: string) =>
  axiosAPI.put(`/delete-forum-post/${id}`);

export {
  getAllForums,
  joinForum,
  createForum,
  getForumChatbox,
  createForumChatMessage,
  closeDeclinedMessage,
  getAllForumsByAdmin,
  getAllForumsByGroups,
  updateForum,
  getForumsByEdit,
  putForumChatboxReview,
  sendReminderForum,
  deleteUpcommingForum,
  getForumParticipantList,
  deleteFormChatFdId,
};
