import axios, { AxiosError, AxiosResponse } from "axios";
import { getLocalStorage, setLocalStorage } from "../utils/localstorage";

// get access token from local storage
const userDetails: any = getLocalStorage("userDetails");

// Skip authorization token for endpoints
const skipAuthorization = [
  { path: "signin" },
  { path: "get-user-by-id", position: 2 },
  { path: "forgot-password" },
  { path: "create-or-change-password" },
  { path: "set-password" },
];

// Create api instance with base url
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}`,
});

// Handle Request on api
const requestInterceptor = (request: any) => {
  const paths = request.url.split("/");

  let skipAuth = false;
  for (const i of skipAuthorization) {
    const { path, position } = i;
    if (path === paths[position || 1]) {
      skipAuth = true;
      break;
    }
  }

  if (!skipAuth) {
    const { accessToken, refreshToken } = userDetails;
    request.headers.Authorization = "Bearer " + accessToken;
    request.headers["x-refresh"] = refreshToken;
  }
  if (request.method === 'get') {
    request.headers.Expires = '0';
    request.headers["Cache-Control"] = 'no-cache';
  }

  return request;
};

// Handle Respose on every api
const responseInterceptor = async (response: AxiosResponse) => {
  const newAccessToken: string = response.headers["x-access-token"];
  if (newAccessToken) {
    setLocalStorage(
      "userDetails",
      JSON.stringify({
        ...userDetails,
        accessToken: newAccessToken,
      })
    );
  }

  if (response.headers["file-name"]) {
    return response;
  }

  return response.data;
};

// Handle Error Response
const ErrorResponseInterceptor = () => (error: AxiosError) => {
  // Check network status
  if (error.message === "Network Error") {
    console.log("Service down.");
    return;
  }

  if (error.response) {
    const { status, data }: any = error.response;

    if (status === 400 && data.errorCode === "RoleChanged") {
      localStorage.clear();
      window.location.href = `/login?roleChanged=true`;
    }
    if (status === 400 && data.errorCode === "accountDeactivated") {
      localStorage.clear();
      window.location.href = `/login?accountDeactivated=true`;
    }

    if (
      status === 401 &&
      (data.message === "Invalid Token" || data.message === "Session Expired")
      // ||
      // data.message === "Decode failed to authenticate."
    ) {
      // Handle if the refresh token also expired and handle session expired
      localStorage.clear();
      window.location.href = "/login?state=session-expired";
    }
  }

  if (error.response) {
    return Promise.reject(error.response.data);
  }
};

// Request Interceptor
instance.interceptors.request.use(requestInterceptor);
// Response Interceptor
instance.interceptors.response.use(
  responseInterceptor,
  ErrorResponseInterceptor()
);

export default instance;
