import { lazy } from 'react';

export default [
  {
    title: 'Component Page',
    component: lazy(() => import('./ComponentPage')),
    url: '/componentpage',
    requirePermission: false,
  },
  {
    title: 'Landing Page',
    component: lazy(() => import('./LandingPage')),
    url: '/landingpage',
    requirePermission: false,
  },
  {
    title: 'Profile',
    component: lazy(() => import('../common/ProfileDetail')),
    url: '/profile',
    requirePermission: true,
  },
  {
    title: 'About',
    component: lazy(() => import('../common/About')),
    url: '/about',
    requirePermission: false,
    noAuth: true,
  },
  {
    title: 'Termsandcondition',
    component: lazy(() => import('../common/Termsandcondition')),
    url: '/termsandcondition',
    requirePermission: false,
    noAuth: true,
  },
  {
    title: 'Privacy',
    component: lazy(() => import('../common/Privacy')),
    url: '/privacy',
    requirePermission: false,
    noAuth: true,
  },
  {
    title: 'Contact',
    component: lazy(() => import('../common/Contact')),
    url: '/contact',
    requirePermission: false,
    noAuth: true,
  },
];
