import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/images/searchIcon.svg";

interface SearchInutProps {
  label?: string;
  addClass?: string;
  type: string;
  name: string;
  value?: string | number | Array<string>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  iconShow?: boolean;
  id?: string;
  onKeyUp?: any;
  onKeyDown?: any;
}

export const SearchInut: React.FC<SearchInutProps> = ({
  label,
  addClass,
  type,
  name,
  value,
  onChange,
  onKeyUp,
  onKeyDown,
  placeholder,
  iconShow,
  id,
}) => {
  return (
    <div className={`input-group ${addClass ? addClass : ""}`}>
      <div className="flex">{label && <label htmlFor={id}>{label}</label>}</div>
      <div className="relative">
        {iconShow && (
          <span className="search-icon">
            <SearchIcon />
          </span>
        )}
        <input
          className="text-field"
          type={type}
          name={name}
          value={value}
          id={id}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
