import axiosAPI from '../middlewares/axios-interceptor';
import * as RequestType from '../types/services';
import { objQueryConversion } from '../utils';

const getSavedResources = (data: RequestType.IgetResources) => {
  return axiosAPI.get(`/resource/favourite/all?${objQueryConversion(data)}`);
};

const getResourcesTypes = () => {
  return axiosAPI.get(`/resource/hub/filters`);
};

const getResources = (data: RequestType.IgetResources) => {
  return axiosAPI.post(`/resource/hub`, data);
};

const favouriteResource = (data: RequestType.IFavouriteResources) => {
  return axiosAPI.post(`resource/favourite`, data);
};

const getRecommendedResources = (data: RequestType.IgetResources) => {
  return axiosAPI.get(`/resource/recommended?${objQueryConversion(data)}`);
};

const getSharedResources = (data: RequestType.paginationWithSearch) => {
  return axiosAPI.get(`/resource/share/user?${objQueryConversion(data)}`);
};

const shareResources = (data: RequestType.IShareResources) => {
  return axiosAPI.post(`resource/share`, data);
};

const deleteSharedResources = (data: RequestType.IDeleteShareResources) => {
  return axiosAPI.delete(`resource/share?${objQueryConversion(data)}`);
};

export {
  getSavedResources,
  getResourcesTypes,
  getResources,
  favouriteResource,
  getRecommendedResources,
  getSharedResources,
  shareResources,
  deleteSharedResources
};
