import { lazy } from "react";

export default [
  {
    title: "Login Account",
    component: lazy(() => import("./Login")),
    url: "/login",
    requirePermission: false,
  },
  {
    title: "Set Password",
    component: lazy(() => import("./SetPassword")),
    url: "/reset-password/:id",
    requirePermission: false,
  },
  {
    title: "Forgot Password",
    component: lazy(() => import("./ForgotPassword")),
    url: "/forgot-password/email",
    requirePermission: false,
  },

  {
    title: "Create Password",
    component: lazy(() => import("./CreateLoginDetails")),
    url: "/create-password/:id",
    requirePermission: false,
  },
  {
    title: "Consent Form",
    component: lazy(() => import("./ConsentForm")),
    url: "/consent-form",
    requirePermission: true,
  },
];
