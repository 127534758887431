import { lazy } from "react";
import { usertypes } from "../../constants";

export default [
  {
    title: "Landing Page",
    component: lazy(() => import("./TypesResources")),
    url: "/resources",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin, usertypes.clinician],
  },
  {
    title: "Types resources Page",
    component: lazy(() => import("./TypesResources")),
    url: "/resources/type-resources",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin, usertypes.clinician],
  },
  {
    title: "Saved resources Page",
    component: lazy(() => import("./SavedResources")),
    url: "/resources/saved-resources",
    requirePermission: true,
    userTypes: [usertypes.patient, usertypes.parent, usertypes.clinician],
  },
  {
    title: "Shared resources Page",
    component: lazy(() => import("./SharedResources")),
    url: "/resources/shared-resources",
    requirePermission: true,
    userTypes: [usertypes.superAdmin, usertypes.admin, usertypes.clinician],
  },
  {
    title: "Recommended resources Page",
    component: lazy(() => import("./RecommendedResources")),
    url: "/resources/recommended-resources",
    requirePermission: true,
    userTypes: [usertypes.patient, usertypes.parent, usertypes.clinician],
  },
];
