import { lazy } from 'react';
import { usertypes } from '../../constants';

export default [
  {
    title: 'Patient Dashboard',
    component: lazy(() => import('./PatientDashboard')),
    url: '/patient/dashboard',
    requirePermission: true,
    userTypes: [usertypes.patient],
  },
  {
    title: 'Admin Dashboard',
    component: lazy(() => import('./AdminDashboard')),
    url: '/admin/dashboard',
    requirePermission: true,
    userTypes: [usertypes.admin],
  },
  {
    title: 'Admin Dashboard',
    component: lazy(() => import('./SuperAdminDashboard')),
    url: '/super-admin/dashboard',
    requirePermission: true,
    userTypes: [usertypes.superAdmin],
  },
];
