import React from 'react';
import '../style/components/button.css';

export interface ButtonProps {
  text?: string;
  addClass?: string;
  type?: 'submit' | 'button';
  disabled?: boolean;
  onClick?: (e: any) => void;
  children?: any;
}

const Button: React.FC<ButtonProps> = ({
  text,
  addClass,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`btn ${addClass}`}
      onClick={props.disabled ? () => {} : props.onClick}
    >
      {text}
      {children}
    </button>
  );
};

export default Button;
