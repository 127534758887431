import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import "./Style.css";
import { Loading, Layout, Header, Footer, SmoothScroll } from "./components";
import { getLocalStorage } from "./utils/localstorage";
import routes from "./routes";
import { LocalStorageUserDetails } from "./types";
import { usertypes } from "./constants";

const PageNotFound = lazy(() => import("./pages/common/PageNotFound"));

// Function allow only the user is signed in
function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const token = getLocalStorage("userDetails");

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function RedirectToDashboard({
  children,
  auth = false,
}: {
  children: JSX.Element;
  auth?: boolean;
}) {
  const token = getLocalStorage("userDetails");

  if (token) {
    if (auth) {
      return children;
    } else if (token.usertype == usertypes.clinician) {
      return <Navigate to="/resources" />;
    } else if (
      token.usertype == usertypes.patient ||
      token.usertype == usertypes.parent
    ) {
      return <Navigate to="/resources/recommended-resources" />;
    } else if (token.usertype == usertypes.admin) {
      return <Navigate to="/user-management" />;
    } else if (token.usertype == usertypes.superAdmin) {
      return <Navigate to="/user-management" />;
    } else {
      return <Navigate to="/resources" />;
    }
  } else {
    return children;
  }
  // return token ? <Navigate to='/patient/dashboard' /> : children;
}

function InitialPage() {
  return <Navigate to="/landingpage" />;
}

function App() {
  const userDetails: LocalStorageUserDetails = getLocalStorage("userDetails");

  return (
    <Suspense
      fallback={
        <div className="grid place-items-center h-screen">
          <Loading />
        </div>
      }
    >
      <SmoothScroll>
        <Routes>
          <Route
            path="/"
            element={
              <RedirectToDashboard>
                <InitialPage />
              </RedirectToDashboard>
            }
          />
          {routes
            .reduce(
              (
                totalValue: any,
                {
                  url,
                  requirePermission,
                  component: Component,
                  title,
                  userTypes = [],
                  noAuth,
                }: any
              ) => {
                if (userTypes.length > 0) {
                  if (
                    userDetails &&
                    userTypes.indexOf(userDetails.usertype) !== -1
                  ) {
                    totalValue.push(
                      <Route
                        key={url}
                        path={url}
                        element={
                          requirePermission ? (
                            <RequireAuth>
                              <Layout>
                                <Component title={title} />
                              </Layout>
                            </RequireAuth>
                          ) : (
                            <RedirectToDashboard auth={noAuth}>
                              <div className="main-section">
                                <Header headerAuth={false} />
                                <Component title={title} />
                                <Footer />
                              </div>
                            </RedirectToDashboard>
                          )
                        }
                      />
                    );
                    return totalValue;
                  }
                  return totalValue;
                }
                totalValue.push(
                  <Route
                    key={url}
                    path={url}
                    element={
                      requirePermission ? (
                        <RequireAuth>
                          <Layout>
                            <Component title={title} />
                          </Layout>
                        </RequireAuth>
                      ) : (
                        <RedirectToDashboard auth={noAuth}>
                          <div className="main-section">
                            <Header headerAuth={false} />
                            <Component title={title} />
                            <Footer />
                          </div>
                        </RedirectToDashboard>
                      )
                    }
                  />
                );
                return totalValue;
              },
              []
            )
            .map((item: any) => item)}
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </SmoothScroll>
    </Suspense>
  );
}

export default App;
