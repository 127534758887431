export function objQueryConversion(data: any) {
    return Object.keys(data)
      .filter((k) => data[k] != null)
      .map((key) => `${key}=${data[key]}`)
      .join('&');
}

export function removeDuplicateFromArray(array: Array<any>){
  return array.filter((item, index) => array.indexOf(item) === index);
}

export async function removeDuplicateFromArrayOfObject(arr: Array<any>, key: string){
  const uniqueIds: any = [];

  const unique = await arr.filter(element => {
    const isDuplicate = uniqueIds.includes(element[key]);

    if (!isDuplicate) {
      uniqueIds.push(element[key]);

      return true;
    }

    return false;
  });
  return unique;
}

export async function DownloadPdfFromLink(name: string, url: string) {
  const headers = new Headers();

  headers.append("Content-Type", "application/pdf");
  headers.append("Accept", "application/pdf");
  headers.append("Access-Control-Allow-Origin", "*");

  // using Java Script method to get PDF file
  fetch(url, {
    mode: "no-cors",
    headers: headers,
  }).then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      const alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = name;
      alink.click();
    });
  });
}
