import React from "react";
import "../style/components/loader.css";
export interface LoadingDotsProps {
  status?: boolean;
  addclass?: string;
  colorCode?: string;
}
const LoadingDots: React.FC<LoadingDotsProps> = ({
  status = true,
  addclass,
  colorCode,
}) => {
  return (
    <>
      {status && (
        <div className="pos-relative h-100 loading-center">
          <div className={`loading-wrap ${addclass}`}>
            <svg id="dots" width="55px" height="30px" viewBox="0 0 132 58">
              <g id="dots" fill="#A3A3A3">
                <circle id="dot1" cx="25" cy="30" r="10"></circle>
                <circle id="dot2" cx="55" cy="30" r="10"></circle>
                <circle id="dot3" cx="95" cy="30" r="10"></circle>
              </g>
            </svg>
          </div>
        </div>
      )}
    </>
  );
};
export default LoadingDots;
