import React from 'react';
import '../style/components/Footer.css';
import logo from '../assets/images/mersey-care-nhs-logo.png';
import { Link } from 'react-router-dom';
import { ScrollTop } from './scrolltotop/ScrollTop';

const Footer: React.FC<any> = () => {
  const tabIndex = 0;
  return (
    <>
      <div className='footer_line'></div>
      <div className='footer_logo_nav_section'>
        <div tabIndex={tabIndex} className='footer_block'>
          <img alt='logo' src={logo} />
        </div>
        <div className='footer_nav_section'>
          <Link to='/about'>About</Link>
          <Link to='/termsandcondition'>Terms and conditions</Link>
          <Link to='/privacy'>Privacy</Link>
          <Link to='/contact'>Contact</Link>
        </div>
      </div>

      <ScrollTop />
    </>
  );
};

export default Footer;
