import React, { useState, useEffect } from 'react';
import { ReactComponent as ArrowBack } from '../../assets/images/uparrow.svg';
interface ScrollTopProps {}
export const ScrollTop: React.FC<ScrollTopProps> = () => {
  const tabIndex = 0;
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className='group_container'>
      {showTopBtn && (
        <span tabIndex={tabIndex} onClick={goToTop} className='onkeyup'>
          <ArrowBack />
        </span>
      )}
    </div>
  );
};
