import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import { useMsal } from '@azure/msal-react';
import logo from '../assets/images/logo.svg';
import profile from '../assets/images/Profileicon.svg';
import '../style/components/header.css';
import Popup from './Popup';
import ErrorPopup from './ErrorPopup';
import { getLocalStorage, capitalizeFirstLetter } from '../utils';
import { LocalStorageUserDetails } from '../types';
import { ReactComponent as Menuicon } from '../assets/images/Menu.svg';
import { ReactComponent as Closeicon } from '../assets/images/close.svg';
import { userRoles } from '../constants';
import { Navbare } from './navbar/Navbare';
import { logOut } from "../services";

export interface HeaderProps {
  text?: string;
  clickMenu?: (e: any) => void;
  headerAuth?: boolean;
  cancelIcon?: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const location = useLocation();

  const userData: LocalStorageUserDetails = getLocalStorage('userDetails');
  // const { instance } = useMsal();
  const wrapperRef: any = useRef(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState('');
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: userData?.name || '',
    usertype: userData?.usertype || '',
  });

  const localStorageListener = () => {
    const data = getLocalStorage('userDetails');
    setUserDetails({
      name: data?.name || '',
      usertype: data?.usertype || '',
    });
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('roleChanged')) {
      setError(true);
      setErrorMsg(
        'Your user info has been changed by Triangle admin team. please re login.'
      );
      window.history.replaceState({}, document.title, '/' + 'login');
    }
    if (query.get('accountDeactivated')) {
      setError(true);
      setErrorMsg('Your account has been deactivated by Admin.');
      window.history.replaceState({}, document.title, '/' + 'login');
    }
  }, [location]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowProfileDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    // Listen for storage event change
    window.addEventListener('storage', localStorageListener);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('storage', localStorageListener);
    };
  }, [wrapperRef]);

  const path = window.location.pathname;
  const tabIndex = 0;
  const handleClick = (e: any) => {
    if (e.keyCode === 13) {
      e.target.click();
    }
  };

  const logout = async () => {
    try {
      await logOut({});
      localStorage.clear();
      navigate('/', { replace: true });
    } catch (err: any) {
      localStorage.clear();
      navigate('/', { replace: true });
    } finally {
      localStorage.clear();
      navigate('/', { replace: true });
    }
  };

  return (
    <>
      <div className='primary-header'>
        <div
          onKeyDown={handleClick}
          tabIndex={tabIndex}
          role='link'
          className={props.headerAuth ? 'header-logo' : 'auth-header'}
          onClick={() => {
            navigate('/');
          }}
        >
          <img src={logo} alt='Triangle'></img>

          {props.headerAuth && (
            <span className='hidden' onClick={props.clickMenu}>
              {!props.cancelIcon ? <Menuicon /> : <Closeicon />}
            </span>
          )}
        </div>
        {!props.headerAuth && (
          <div className='flex gap-4 mr-7'>
            {path !== '/login' && <Link to='/login'>LOG IN</Link>}
          </div>
        )}
        {props.headerAuth && (
          <div className='secondary-header'>
            <h1 tabIndex={tabIndex} className='h1-text'>
              {/* {capitalizeFirstLetter(userData.trustName) || ''} */}&#8203;
            </h1>
            <div>
              <div className='account-blk'>
                <div>
                  <p tabIndex={tabIndex}>
                    {capitalizeFirstLetter(userDetails.name) || ''}
                  </p>
                  <p tabIndex={tabIndex}>
                    {capitalizeFirstLetter(userRoles[userDetails.usertype])}
                  </p>
                </div>
                <div
                  className={`p-0.5 ${
                    path === '/profile' || path === '/account'
                      ? 'active-profile'
                      : 'inactive-profile'
                    }`}
                  ref={wrapperRef}
                >
                  <img
                    src={profile}
                    alt='profile'
                    onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                    aria-hidden='true'
                  ></img>
                  {showProfileDropdown && (
                    <div>
                      <div className='menu-dropdown'></div>
                      <div>
                        <div
                          className='sub-menu-dropdown'
                          onClick={() => {
                            setShowProfileDropdown(false);
                          }}
                        >
                          <div
                            tabIndex={tabIndex}
                            onClick={() => navigate('/profile')}
                          >
                            Account
                          </div>
                          {/* {userData.usertype !== usertypes.superAdmin && (
                            <div onClick={() => navigate('/profile')}>
                              Account
                            </div>
                          )} */}
                          <div onClick={() => setShowMenu('logout')}>
                            Logout
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <ErrorPopup
          open={error}
          handleClose={() => setError(false)}
          message={errorMsg}
        />

        {showMenu === 'logout' && (
          <Popup
            addClass='h-250'
            headerText='Are you sure, you would like to logout ?'
            btnText='OK'
            onBtnClick={async () => {
              logout();
            }}
            onClose={() => {
              setShowMenu('');
            }}
          />
        )}
      </div>
      {props.headerAuth && (
        <>
          <Navbare />
        </>
      )}
    </>
  );
};

export default Header;
