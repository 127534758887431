import axiosAPI from "../middlewares/axios-interceptor";
import * as RequestType from "../types/services";

// Dashboard

const listQuesDashboard = () => axiosAPI.get(`/questionnaire/dashboard/new`);
// Library

const listQuesLibrary = (params: RequestType.ListQuesLibrary) =>
  axiosAPI.get(
    `/questionnaire/approve/submit?pageNo=${params.pageNo}&perPage=${params.perPage}&sortType=${params.sortType}&search=${params.search}`
  );
const dropdownRoles = () => axiosAPI.get(`/role/dropdown`);
const usersByRole = (id: string) => axiosAPI.get(`/user-list/role/${id}`);
const usersByRoleIds = (ids: any) =>
  axiosAPI.get(`/user-list/roles?roleIds=${ids}`);
const createSingleShare = (data: RequestType.CreateSingleShare) =>
  axiosAPI.post(`/questionnaire/share`, data);
const createSingleSchedule = (data: RequestType.CreateSingleSchedule) =>
  axiosAPI.post(`/questionnaire/schedule/share`, data);

const createMultipleShare = (data: RequestType.CreateMultipleShare) =>
  axiosAPI.post(`/questionnaire/epr/share`, data);
const createMultipleSchedule = (data: RequestType.CreateMultipleSchedule) =>
  axiosAPI.post(`/questionnaire/multiple/schedule/share`, data);

const updateSchedule = (data: RequestType.UpdateSchedule) =>
  axiosAPI.put(`/questionnaire/multiple/schedule/share`, data);

const sharedQuesList = (params: RequestType.SharedQuesList) =>
  axiosAPI.get(
    `/user/list/questionnaire?pageNo=${params.pageNo}&perPage=${params.perPage}&search=${params.search}`
  );
const sharedQuesListDetail = (params: RequestType.SharedQuesListDetail) =>
  axiosAPI.get(
    `/questionnaire/patient/forms/list?userId=${params.userId}&pageNo=${params.pageNo}&perPage=${params.perPage}&search=${params.search}`
  );

const sharedQuesAnswer = (params: RequestType.SharedQuesAnswer) =>
  axiosAPI.get(
    `/questionnaire/answer?questionSetId=${params.questionSetId}&shareId=${params.shareId}`
  );
const listSchedule = (params: RequestType.ListSchedule) =>
  axiosAPI.get(
    `/questionnaire/schedule/share?pageNo=${params.pageNo}&perPage=${params.perPage}&search=${params.search}`
  );

// Patient Flow

const patientQuesListDetail = (params: RequestType.PatientQuesListDetail) =>
  axiosAPI.get(
    `/questionnaire/patient/forms?type=${params.type}&pageNo=${params.pageNo}&perPage=${params.perPage}&search=${params.search}`
  );
const patientQuesListDetail1 = () =>
  axiosAPI.get(`/questionnaire/dashboard/new`);

const patientQuesView = (id: string) => axiosAPI.get(`/questionnaire/${id}`);

const patientAnswer = (data: RequestType.PatientAnswer) =>
  axiosAPI.post(`/questionnaire/answer`, data);

  const adminAnswer = (data: RequestType.AdminAnswer) =>
  axiosAPI.post(`/questionnaire/admin/answer`, data);


const sendReminderPost = (data: RequestType.SendReminder) =>
  axiosAPI.post(`/questionnaire/multiple/instantReminder`, data);

const deleteScheduled = (ids: any) =>
  axiosAPI.delete(
    `/questionnaire/schedule/share?scheduleQuestionnaireIds=${ids}`
  );

const deleteShare = (ids: any) =>
  axiosAPI.delete(`/questionnaire/share?shareId=${ids}`);

const downloadPdf = (params: RequestType.DownloadPdf) =>
  axiosAPI.get(
    `/questionnaire/answer/response/pdf?questionSetId=${params.questionSetId}&shareId=${params.shareId}`
  );

const getSharingResource = (data: RequestType.GetSharingResource) =>
  axiosAPI.post(`/resource/hub`, data);

const putSharingResource = (id: string, data: any) =>
  axiosAPI.put(`/resource/recommend/${id}`, data);

export {
  listQuesDashboard,
  listQuesLibrary,
  dropdownRoles,
  usersByRole,
  createSingleShare,
  createSingleSchedule,
  createMultipleShare,
  createMultipleSchedule,
  updateSchedule,
  sharedQuesList,
  sharedQuesListDetail,
  sharedQuesAnswer,
  patientQuesListDetail,
  patientQuesView,
  patientAnswer,
  adminAnswer,
  listSchedule,
  sendReminderPost,
  deleteScheduled,
  downloadPdf,
  getSharingResource,
  putSharingResource,
  usersByRoleIds,
  deleteShare,
  patientQuesListDetail1,
};
