import { useField } from 'formik';
import React, { useState } from 'react';
import { ReactComponent as Password } from '../assets/images/View.svg';
import { ReactComponent as PasswordHidden } from '../assets/images/hidden.svg';
import { ReactComponent as Search } from '../assets/images/searchIcon.svg';
import { ReactComponent as InfoIcon } from '../assets/images/InfoIcon.svg';
import { ReactComponent as ShowInfoIcon } from '../assets/images/showInfoIcon.svg';
import { useNavigate } from 'react-router-dom';
import '../style/components/textinput.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  time?: boolean;
  label?: string;
  addClass?: string;
  type?: string;
  icon?: 'search' | 'password' | 'delete' | 'file' | 'toggle' | null;
  onIconClick?: (e: any) => void;
  showInfoIcon?: boolean;
  showForgot?: boolean;
  id?: string;
  autoComplete?:string;
}

const TextInput: React.FC<Props> = ({
  label = '',
  addClass = '',
  type = '',
  icon = '',
  id = '',
  onIconClick,
  showInfoIcon,
  showForgot,
  autoComplete='off',
  ...props
}) => {
  const [field, meta] = useField({ name: props.name });
  const [showInfo, setshowInfo] = useState(false);
  const Navigate = useNavigate();
  const tabIndex = 0;
  return (
    <div id={id} className={`input-group ${addClass ? addClass : ''}`}>
      <div className='flex'>
        {label && <label htmlFor={id}>{label}</label>}
        {showInfoIcon && (
          <span
            className='info_icon'
            onClick={() => (showInfo ? setshowInfo(false) : setshowInfo(true))}
          >
            {showInfo ? <InfoIcon /> : <ShowInfoIcon />}
          </span>
        )}
      </div>
      <div className='relative'>
        <input
          autoComplete={autoComplete}
          className={`text-field  ${meta.touched && meta.error ? 'error' : ''}`}
          id={id}
          {...field}
          {...props}
          type={type}
        />
        {icon === 'password' && (
          <div className='input-password onkeyup' onClick={onIconClick}>
            {type === 'password' ? <Password /> : <PasswordHidden />}
          </div>
        )}
        {icon === 'search' && (
          <div className='input-password onkeyup' onClick={onIconClick}>
            {type === 'search' ? <Search /> : ''}
          </div>
        )}
      </div>
      <div
        className={`flex items-baseline ${
          meta.touched && meta.error ? 'justify-between' : 'justify-end'
        }`}
      >
        {meta.touched && meta.error ? (
          <div className='aw-error'>{meta.error}</div>
        ) : null}
        {showForgot && (
          <div className='password-blk'>
            <p
              tabIndex={tabIndex}
              onClick={() => {
                Navigate('/forgot-password/email');
              }}
            >
              Forgot password?
            </p>
          </div>
        )}
      </div>
      {showInfo && (
        <div className='psw_info_block'>
          <span tabIndex={tabIndex}>Please make sure your password has:</span>
          <span tabIndex={tabIndex}>- A minimum of 8 characters</span>
          <span tabIndex={tabIndex}>
            - Uses uppercase & lowercase characters
          </span>
          <span tabIndex={tabIndex}>
            - Has at least one special character ex:-(!, @, &)
          </span>
        </div>
      )}
    </div>
  );
};

export default TextInput;
