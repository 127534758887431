import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/components/layout.css';
import Button from './Button';
import Header from './Header';
import Footer from './Footer';
import { usertypes } from '../constants';
import { getLocalStorage } from '../utils/localstorage';
import { LocalStorageUserDetails } from '../types';

interface LayoutProps {
  children?: React.ReactNode;
}

const startPrediction = {
  name: 'Upload and run CSV data',
  url: '/dashboard',
  order: 1,
};
const viewPrediction = {
  name: 'View previous predictions',
  url: '/predictions',
  order: 2,
};
const resource = { name: 'Resources', url: '#', order: 3 };
const audit = { name: 'Audit trail', url: '/audit-logs', order: 4 };
const users = { name: 'Users', url: '/users', order: 5 };
const organisation = {
  name: 'Organisation/Trust',
  url: '/registered-organisations',
  order: 6,
};

const Layout: React.FC<LayoutProps> = ({ children }: any) => {
  const navigate = useNavigate();
  const currentMenu = [];
  const [showActionMenu, setShowActionMenu] = useState('');
  const [showCancelIcon, setshowCancelIcon] = useState(false);

  const userDetails: LocalStorageUserDetails = getLocalStorage('userDetails');

  if (userDetails.usertype === usertypes.admin) {
    currentMenu.push(startPrediction);
    currentMenu.push(resource);
    currentMenu.push(viewPrediction);
    currentMenu.push(audit);
    currentMenu.push(users);
  } else if (userDetails.usertype === usertypes.clinician) {
    currentMenu.push(startPrediction);
    currentMenu.push(resource);
    currentMenu.push(viewPrediction);
  } else if (userDetails.usertype === usertypes.superAdmin) {
    currentMenu.push(resource);
    currentMenu.push(organisation);
  }

  function outputEvent() {
    if (showActionMenu == 'showMenu') {
      setshowCancelIcon(false);
      setShowActionMenu('hideMenu');
    } else {
      setshowCancelIcon(true);
      setShowActionMenu('showMenu');
    }
  }

  currentMenu.sort((a: any, b: any) => a.order - b.order);

  // Get dynamic height in body

  const [headerHeight, setHeaderHeight] = useState('');
  const [footerHeight, setFooterHeight] = useState('');

  const headerRef: any = useRef(null);
  const footerRef: any = useRef(null);

  const handleResize = () => {
    setHeaderHeight(headerRef?.current?.clientHeight);
    setFooterHeight(footerRef?.current?.clientHeight);
  };

  // Get dynamic height
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [headerHeight, footerHeight]);

  const totalHeight: any = headerHeight + footerHeight;
  // const customHeight = `calc(100vh - ${totalHeight.toString()}px)`;
  let navBodyHeight = window.innerHeight;
  navBodyHeight = navBodyHeight - totalHeight;

  return (
    <Fragment>
      <header ref={headerRef}>
        <Header
          clickMenu={outputEvent}
          headerAuth={true}
          cancelIcon={showCancelIcon}
        />
      </header>
      <main className='flex' style={{ minHeight: navBodyHeight }}>
        <div className={`side-bar side-bar-width hidden ${showActionMenu}`}>
          <div>
            {currentMenu.map((item: any) => (
              <div className='sidebar-btn' key={item.name}>
                <Button
                  addClass={`secondary-btn ${
                    window.location.pathname.includes(item.url)
                      ? 'secondary-btn-active'
                      : ''
                  }`}
                  text={item.name}
                  onClick={() => {
                    navigate(item.url);
                    setShowActionMenu('hideMenu');
                    setshowCancelIcon(false);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className='main-content'>{children}</div>
      </main>
      <footer ref={footerRef}>
        <Footer />
      </footer>
    </Fragment>
  );
};

export default Layout;
